import Price from 'types/Price';
import { FormattedMessage } from 'utils/intl';
import formatAmount from 'utils/formatAmount';

import messages from './AmountFormetter.messages';

type Props = {
  price: Price;
  type?: 'net' | 'gross';
};

const AmountFormatter = ({ price, type }: Props): JSX.Element => {
  const message = type ? messages[type] : undefined;

  return message ? (
    <FormattedMessage
      {...message}
      values={{
        price: formatAmount(price),
      }}
    />
  ) : (
    <>{formatAmount(price)}</>
  );
};

export default AmountFormatter;
